
<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <!-- Add New -->
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('tour_management.feedback_and_review') + ' ' + $t('globalTrans.list')}}</h4>
          </template>
          <!-- <template v-slot:headerAction>
            <b-button class="btn_add_new"  @click="addForm">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template> -->
          <!-- /Add New -->
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">

                  <!-- Table -->
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>

                    <template v-slot:cell(status)="data">
                          {{ getStatus(data.item.status) }}
                    </template>

                    <template v-slot:cell(action)="data">
                     <a href="javascript:" class="btn_table_action table_action_view" title="View" v-b-modal.modal-5 @click="details(data.item)"><i class="ri-eye-line m-0 "></i></a>
                    </template>
                  </b-table>

                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
<!--<b-modal id="modal-5" size="lg" :title="$t('pp_visit_request.visit_request')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="detailItem" :key="item"></Details>
    </b-modal>

    <b-modal id="modal-3" size="lg" :title="formTitle"  :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal> -->

  </b-container>
</template>
<script>
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { phyVisitAdminFeedbackReviewApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
  },
  data () {
    return {
      search: {
        org_id: this.$store.state.dataFilters.orgId ? this.$store.state.dataFilters.orgId : 0
      },
      editItemId: 0,
      itemId: 0,
      // rows: [],
      detailItem: '',
      userDetails: []
    }
  },
  computed: {
    formTitle () {
        if (this.editItemId === 0) {
            return this.$t('pp_visit_request.visit_request') + ' ' + this.$t('globalTrans.entry')
        } else {
            return this.$t('pp_visit_request.visit_request') + ' ' + this.$t('globalTrans.modify')
        }
    },
    requestTypeList: function () {
        const list = [
        { value: 1, text_en: 'Individual', text_bn: 'স্বতন্ত্র' },
        { value: 2, text_en: 'Institutional', text_bn: 'প্রাতিষ্ঠানিক' }
        ]
        return list
    },
    columns () {
      // Table Column Labels
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('globalTrans.organization'), class: 'text-center' },
          { label: this.$t('tour_management.reviewer_name'), class: 'text-center' },
          { label: this.$t('tour_management.reviewer_id'), class: 'text-center' },
          { label: this.$t('tour_management.review'), class: 'text-center' },
          { label: this.$t('tour_management.feedback_message'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
          { key: 'reviewer_name_bn' },
          { key: 'reviewer_id' },
          { key: 'star_rate' },
          { key: 'feedback' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'reviewer_name_en' },
          { key: 'reviewer_id' },
          { key: 'star_rate' },
          { key: 'feedback' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  created () {
    this.loadData()
  },
  watch: {
  },
  methods: {
    addForm () {
      this.$router.push({ path: 'add-disease' })
    },
    details (item) {
      this.detailItem = item
      this.itemId = item.id
    },
    edit (item) {
      this.editItemId = item.id
    },
    searchData () {
      this.loadData()
    },
    // remove (item) {
    //   this.changeStatus(virtualMuseumServiceBaseUrl, diseaseManageToggleStatusApi, item)
    // },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(virtualMuseumServiceBaseUrl, phyVisitAdminFeedbackReviewApi, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getRelationalData(response.data, response.userDetails))
            this.userDetails = response.data.userDetails
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data, userDetails) {
            let orgList = {}
            let userObj = {}
            const listData = data.map(item => {
                orgList = this.$store.state.commonObj.organizationProfileList.find(org => org.value === item.org_id)
                userObj = userDetails.find(usr => usr.value === item.reviewer_id)
            //    const rqType = this.requestTypeList.find(rql => rql.value === item.request_type)
            // const rqTypeObj = {
            // request_type: rqType !== undefined ? rqType.text_en : '',
            // request_type_bn: rqType !== undefined ? rqType.text_bn : ''
            //  }
               const data = {
                    org_name: orgList !== undefined ? orgList.text_en : '',
                    org_name_bn: orgList !== undefined ? orgList.text_bn : '',
                    reviewer_name_bn: userObj !== undefined ? userObj.text_bn : '',
                    reviewer_name_en: userObj !== undefined ? userObj.text_en : ''
                }
                const timeSlotObj = {
                    time_slot: item.opening_time + '---' + item.closeing_time
                }

                return Object.assign({}, item, timeSlotObj, data)
            })
            return listData
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('budget.pending')
      } else if (status === 2) {
        return this.$t('pp_visit_request.reviewed')
      } else if (status === 3) {
        return this.$t('budget.approve')
      } else if (status === 4) {
        return this.$t('pp_visit_request.visited')
      } else if (status === 5) {
        return this.$t('tour_management.reject')
      }
    }
  }
}
</script>
