// Visit request routes

export const phyVisitReqListApi = '/tour-management/visit-request/visit-list'
export const phyVisitAdminFeedbackApi = '/tour-management/visit-request/admin-feedback'
export const phyVisitAdminFeedbackReviewApi = '/tour-management/visit-request/feedback-and-review'
export const phyVisitAdminReportApi = '/tour-management/visit-request/reports'
export const phyVisitAdminVisitedApi = '/tour-management/visit-request/visited'

// Nothi api
export const vmuseumnothi = 'vmuseumnothi/'
// export const pendingNothiVenueList = nothi + 'venue-sent-nothi'
export const pendingNothiVisitRequestList = vmuseumnothi + 'visit-request-sent-nothi'

// export const reSentNothiVenueList = nothi + 'venue-re-sent-nothi'
export const reSentNothiVisitRequestList = vmuseumnothi + 'visit-request-re-sent-nothi'
